declare global {
  interface Window {
    dataLayer?: any[];
  }
}

import React from 'react';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';

import sidekick from '@last-rev/contentful-sidekick-util';

import Grid from '../Grid';
import ContentModule from '../ContentModule';

import type { SiteMessageProps, SiteMessageOwnerState } from './SiteMessage.types';

import MuiCloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Background from '../Background';

const SiteMessage = (props: SiteMessageProps) => {
  const ownerState = { ...props };

  const [open, setOpen] = React.useState(true);

  const { backgroundColor, icon, text, mobileText, link, sidekickLookup } = props;

  const handleLinkClick = (eventName: string, href: string) => {
    if (window.dataLayer && href) {
      window.dataLayer.push({
        event: eventName,
        siteMessageUrl: href
      });
    }
  };

  const handleContentWrapClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const closestLink = target?.closest('a');

    if (closestLink) {
      const href = closestLink.getAttribute('href');
      const inlineTextParent = closestLink.closest('#InlineText');

      if (href && inlineTextParent) {
        handleLinkClick('siteMessageLinkClick', href);
      }
    }
  };

  return (
    <Root
      {...sidekick(sidekickLookup)}
      ownerState={ownerState}
      data-testid="SiteMessage-SiteMessage"
      sx={{ display: open ? 'block' : 'none' }}>
      <SiteMessageBackground
        backgroundColor={backgroundColor ?? 'white'}
        data-testid="SiteMessage-background"
      />

      <ContentOuterGrid ownerState={ownerState}>
        <ContentWrap ownerState={ownerState} onClick={handleContentWrapClick}>
          {icon && <Icon {...icon} ownerState={ownerState} />}

          {(text || mobileText) && (
            <Box id="InlineText">
              {text && (
                <DesktopText
                  __typename="RichText"
                  body={text}
                  ownerState={ownerState}
                  data-testid="SiteMessage-DesktopText"
                />
              )}
              {mobileText && (
                <MobileText
                  __typename="RichText"
                  body={mobileText}
                  ownerState={ownerState}
                  data-testid="SiteMessage-MobileText"
                />
              )}
            </Box>
          )}

          {link && (
            <LinkWrap>
              <Link onClick={() => handleLinkClick('siteMessageCtaClick', link.href)} {...link} />
            </LinkWrap>
          )}

          <CloseIconWrap>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <CloseIcon fontSize="small" ownerState={ownerState} />
            </IconButton>
          </CloseIconWrap>
        </ContentWrap>
      </ContentOuterGrid>
    </Root>
  );
};

const Root = styled(Box, {
  name: 'SiteMessage',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: SiteMessageOwnerState }>``;

const SiteMessageBackground = styled(Background, {
  name: 'SiteMessage',
  slot: 'SiteMessageBackground',
  overridesResolver: (_, styles) => [styles.background]
})<{}>``;

const CloseIconWrap = styled(Box, {
  name: 'SiteMessage',
  slot: 'CloseIconWrap',
  overridesResolver: (_, styles) => [styles.closeIconWrap]
})<{ ownerState?: SiteMessageOwnerState }>``;

const CloseIcon = styled(MuiCloseIcon, {
  name: 'SiteMessage',
  slot: 'CloseIcon',
  overridesResolver: (_, styles) => [styles.closeIcon]
})<{ ownerState: SiteMessageOwnerState }>``;

const ContentOuterGrid = styled(Grid, {
  name: 'SiteMessage',
  slot: 'ContentOuterGrid',
  overridesResolver: (_, styles) => [styles.contentOuterGrid]
})<{ ownerState: SiteMessageOwnerState }>``;

const ContentWrap = styled(Box, {
  name: 'SiteMessage',
  slot: 'ContentWrap',
  overridesResolver: (_, styles) => [styles.contentWrap]
})<{ ownerState: SiteMessageOwnerState }>``;

const LinkWrap = styled(Box, {
  name: 'SiteMessage',
  slot: 'LinkWrap',
  overridesResolver: (_, styles) => [styles.linkWrap]
})<{ ownerState?: SiteMessageOwnerState }>``;

const Icon = styled(ContentModule, {
  name: 'SiteMessage',
  slot: 'Icon',
  overridesResolver: (_, styles) => [styles.icon]
})<{ ownerState: SiteMessageOwnerState }>``;

const Link = styled(ContentModule, {
  name: 'SiteMessage',
  slot: 'Link',
  overridesResolver: (_, styles) => [styles.link]
})<{ ownerState?: SiteMessageOwnerState }>``;

const DesktopText = styled(ContentModule, {
  name: 'SiteMessage',
  slot: 'DesktopText',
  overridesResolver: (_, styles) => [styles.desktopText]
})<{ ownerState: SiteMessageOwnerState }>``;

const MobileText = styled(ContentModule, {
  name: 'SiteMessage',
  slot: 'MobileText',
  overridesResolver: (_, styles) => [styles.mobileText]
})<{ ownerState: SiteMessageOwnerState }>``;

export default SiteMessage;
